import React from 'react';
import Script from 'next/script';
import { GA_TRACKING_ID } from '../../helpers/constants/googleAnalytics';
const GASnippet = () => {
    return (<>
      <Script src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} strategy="afterInteractive"/>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}');
        `}
      </Script>
    </>);
};
export default GASnippet;
